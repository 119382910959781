@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  border: none;
  outline: none;
  scroll-behavior: smooth;
  font-family: 'Poppins', sans-serif;
}

/* :root{
  --bg-color: #fdfdfd;
  --text-color: #333;
  --main-color: #754ef9;
  --white-color: #fdfdfd;
  --shadow-color: rgba(0,0,0,0.2);
} */

:root{
  --bg-color: #0f1f38;
  --text-color: #fdfdfd;
  --main-color: #ffbe76;
  --white-color: #fdfdfd;
  --shadow-color: rgba(0,0,0,0.7);
}


html{
  font-size: 62.5%;
  overflow-x: hidden; 
}

body{
  background-color: var(--bg-color);
  color: var(--text-color);
}

.header{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 2rem 7%;
  background: var(--bg-color);
  display: flex;
  align-items: center;
  z-index: 100;
}

.logo{
  font-size: 2.5rem;
  color: var(--main-color);
  font-weight: 600;
  cursor: default;
  margin-right: auto;
}

.navbar a{
  position: relative;
  font-size: 1.7rem;
  color: var(--main-color);
  font-weight: 500;
  margin-right: 3.5rem;
}

.navbar a.active{
  color: var(--white-color);
}

#menu-icon {
  font-size: 3.6rem;
  color: var(--text-color);
  cursor: pointer;
  display: none;
}

section{
  min-height: 100vh;
  padding: 10rem 7% 2rem;
}

.home{
  display: flex;
  justify-content: center;
  align-items: center;
}

.home .home-content {
  max-width: 50rem;
}

.home-content h3 {
  font-size: 3.2rem;
  font-weight: 700;
  line-height: .3;
}

.home-content h1 {
  font-size: 5.6rem;
  font-weight: 700;
  margin-bottom: .3rem;
}

.home-content p {
  font-size: 1.6rem;
}

.home-content .social-media a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: var(--main-color);
  margin: 2.5rem 1.5rem 3rem 0;
  transition: .5s ease;
}

.home-content .social-media a:hover{
  color: var(--white-color);
}

.btn{
  display: inline-block;
  padding: 1.2rem 2.8rem;
  background: var(--main-color);
  border-radius: .6rem;
  box-shadow: 0 .2rem .5rem var(--shadow-color);
  font-size: 1.6rem;
  color: var(--white-color);
  letter-spacing: .1rem;
  font-weight: 600;
  border: .2rem solid transparent;
  transition: .5s ease;
}

.btn:hover{
  background: transparent;
  color: var(--main-color);
  border-color: var(--main-color);
}

.home-img img {
  width: 450px;
  height: 400px;
  /* max-width: 100%;
  max-height: 100%; */
  border-radius: 50%;
  margin-left: 2rem;
}

span {
  color: var(--main-color);
}

.skills {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 20rem;
}

.heading {
  font-size: 4.5rem;
  text-align: center;
}

.skills .heading {
  margin-bottom: 5rem;
}

.qualification {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 2rem;
}

.qualification-content h3 {
  font-size: 2rem;
  cursor: pointer;
  transition: .5s ease;
}

.qualification-content h3:hover {
  color: var(--main-color);
}

.qualification-content .active {
  color: var(--main-color);
}

.qualification_data {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  column-gap: 1.5rem;
}

.qualification_title {
  font-size: medium;
  font-weight: bold;
}

.qualification_subtitle {
  display: inline-block;
  font-size: small;
  margin-bottom: 1rem;
}

.qualification_calender {
  font-size: small;
  color: var(--text-color);
}

.qualification_rounder {
  display: inline-block;
  width: 13px;
  height: 13px;
  background-color: yellow;
  border-radius: 50%;
}

.qualification_line {
  display: block;
  width: 1px;
  height: 100%;
  background-color: #fdfdfd;
  transform: translate(6px, -7px);
}

.technology-content h2 {
  text-align: center;
  line-height: 1.2;
}

.technology-content h3 {
  font-size: 2rem;
}

.technology-content p {
  font-size: 1.6rem;
  margin: 2rem 0 3rem;
}

.technology-content .technologies{
  margin-top: 5rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  text-align: center;
  gap: 2.5rem;
}

.technology-content i{
  font-size: 3.6rem;
  color: var(--text-color);
}

.portfolio {
  padding-bottom: 10rem;
}

.portfolio h2 {
  margin-bottom: 4rem;
}

.portfolio .portfolio-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  gap: 2.5rem;
}

.portfolio-container .portfolio-box {
  position: relative;
  display: flex;
  border-radius: 2rem;
  box-shadow: 0 0 1rem rgba(0,0,0,0.1);
  overflow: hidden;
}

.portfolio-box img {
  width: 100%;
  transition: .5s ease;
}

.portfolio-box .portfolio-layer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .7);
  color: var(--white-color);
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 0 4rem;
  justify-content: center;
  opacity: 0;
  transition: .5s ease;
}

.portfolio-box:hover .portfolio-layer {
  opacity: 1;

}

.portfolio-layer h4 {
  font-size: 2rem;
}

.portfolio-layer p {
  font-size: 1.4rem;
  margin: 0.3rem 0 1rem;
}


.portfolio-layer .portfolio-icons {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  color: var(--white-color);
  transition: .5s ease;
}

.portfolio-layer .portfolio-icons a{
  margin-top: 10px;
  color: var(--white-color);
}

.portfolio-layer a:hover{
  color: var(--main-color);
}

.contact h2 {
  margin-bottom: 3rem;
}

.contact form {
  max-width: 70rem;
  margin: 1rem auto;
  text-align: center;
  margin-bottom: 3rem;
}

.contact form .input-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.contact form .input-box input,
.contact form textarea {
  width: 100%;
  padding: 1.5rem;
  font-size: 1.6rem;
  color: var(--text-color);
  background: var(--bg-color);
  border: 1px solid var(--white-color);
  border-radius: .8rem;
  margin: .7rem 0;
  box-shadow: 0 .1rem .5rem var(--shadow-color);
}

.contact form .input-box input {
  width: 49%;
}

.contact form textarea {
  resize: none;
}

.contact form .btn {
  margin-top: 2rem;
  margin-bottom: 2rem;
  cursor: pointer;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 2rem 7%;
  background: var(--main-color);
}

.footer-text p {
  font-size: 1.6rem;
  color: var(--white-color);
}


@media (max-width: 1200px) {
  html {
    font-size: 55%;
  }
}

@media (max-width: 1024px) {
  .header {
    padding: 2rem 3%;
  }

  section {
    padding: 10rem 3% 2rem;
  }

}

@media (max-width: 991px) {
  .home {
    flex-direction: column;
    margin-top: 15rem;
  }

  .home .home-content {
    max-width: 50rem;
  }

  .home-img img {
    margin-top: 7rem;
    margin-left: 0;
  }

  .services, .portfolio {
    padding-bottom: 7rem;
  }

  .contact {
    min-height: auto;
  }

  .footer {
    padding: 2rem 3%;
  }

  .portfolio .portfolio-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  #menu-icon {
    display: block;
  }

  .navbar {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 1rem 3%;
    background-color: var(--bg-color);
    border-top: .1rem solid rgba(0,0,0,0.2);
    box-shadow: 0 .5rem 1rem rgba(0,0,0,0.1);
  }

  .navbar.active {
    display: none;
  }

  .navbar a {
    display: block;
    font-size: 2rem;
    margin: 3rem 0;
    color: var(--text-color);
  }

  .navbar a.active {
    color: var(--main-color);
  }

  .navbar a::before {
    display: none;
  }

  .home {
    padding: 0 3% 23rem;
    justify-content: center;
    text-align: center;
  }

  .home-content h3 {
    font-size: 2.6rem;
  }

  .home-content h1 {
    font-size: 5rem;
  }

  .home-content .social-media a {
    margin: 2.5rem .75rem 3rem;
  }

  .portfolio h2 {
    margin-bottom: 3rem;
  }
  
}

@media (max-width: 617px) {
  .portfolio .portfolio-container {
    grid-template-columns: 1fr;
  }

  .home-img img {
    width: 400px;
    height: 350px;
  }
}

@media (max-width: 450px) {
  html {
    font-size: 50%;
  }

  .home {
    padding: 0 3% 30rem;
  }

  .home-img img {
    width: 350px;
    height: 300px;
  }

  .contact form .input-box input {
      width: 100%;
  }

}

@media (max-width: 380px) {

  .home-img img {
    width: 300px;
    height: 250px;
  }

}

@media (max-width: 380px) {

  .home-img img {
    width: 280px;
    height: 250px;
  }

}

@media (max-width: 300px) {

  .home-img img {
    width: 230px;
    height: 200px;
  }

}
  